<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body card-height">
        <div class="mb-2">
          <h3>
            ยืนยันสินค้านำเข้า
          </h3>
        </div>
        <import-list
          :id="id"
          :type="text"
        />
      </div>
      <!-- end card-body -->
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import ImportList from '../import/import_list.vue'

export default {
  components: {
    ImportList
  },
  data () {
    return {
      text: 'ยืนยัน',
      id: ''
    }
  },
  watch: {
  },
  created () {
    this.id = this.$route.params.id
  },
  methods: {

  }
}
</script>

<style >
.modal-title {
  color: white;
}
</style>
